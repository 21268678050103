import * as React from 'react';

import 'front-components/src/styles/colorPallete.css';
import 'front-components/src/styles/fonts.css';
import 'front-components/src/styles/spacing.css';

import { navigate } from 'gatsby';
import PageKey from '@myneva-portals/models/src/models/page-key';

const IndexPage = () => {
  React.useEffect(() => {
    navigate(PageKey.RESELLERS);
  }, []);

  return <div></div>;
};

export default IndexPage;
